












import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import BackBar from '@/components/BackBar.vue';
import jwlWbt from '@/components/jwlWbt.vue';
import Wbt from '@/store/Wbt';

const IconArrowLeft = () => import('common-modules/src/assets/fa-icons/solid/arrow-left.svg');
const IconArrowRight = () => import('common-modules/src/assets/fa-icons/solid/arrow-right.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

const HIDE_MENU_CLASS = 'jwl-body--hide-menu';

@Component({
  components: {
    BackBar,
    IconArrowLeft,
    IconArrowRight,
    jwlWbt,
    CommonError,
  },
})
export default class WbtPreview extends Vue {
  wbt: Wbt | null = null;
  unit = null;
  error = null;
  loading = true;

  @Watch('$route')
  fetchData (): void {
    this.loading = true;
    const unitId = this.$route.params.unit;
    const { wbtIndex } = this.$route.params;
    this.$store.dispatch('getData', `wbt/${unitId}/${wbtIndex}/preview`)
      .then((data) => {
        this.wbt = new Wbt(data.wbt);
        this.unit = data.unit;
        this.loading = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  created (): void {
    this.fetchData();
  }

  mounted (): void {
    document.body.classList.add(HIDE_MENU_CLASS);
  }

  beforeDestroy (): void {
    document.body.classList.remove(HIDE_MENU_CLASS);
  }
}
